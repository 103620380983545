export default {
    init() {
        // JavaScript to be fired on all pages

        // // Jquery-match-height
        $(function() {
            setTimeout(function(){
                $('.product__content').matchHeight();
                $('.product__content__inner').matchHeight();
                $('.overview__block__content').matchHeight();
                $('.match-height').matchHeight();
            }, 300);
        });


        // // Slick slider example
        $('.products__slider').slick({
            arrows: true,
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            swipeToSlide: true,
            prevArrow: $('.products__slider__arrow.prev'),
            nextArrow: $('.products__slider__arrow.next'),
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $('.sidebar__gallery__top').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.sidebar__gallery__bottom',
        });

        $('.sidebar__gallery__bottom').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.sidebar__gallery__top',
            dots: false,
            arrows: false,
            centerMode: false,
            focusOnSelect: true,
            swipeToSlide: true,
            centerPadding: '0',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        });

        $('.menu__left__dropdown').click(function() {
            $('.menu__left__outer').toggleClass('menu__left__outer__active');
        });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
